<template>
  <section class="list">
    <lottery-single
      v-for="lottery in lotteries"
      :key="lottery.id"
      :hour="getHour(lottery['created_at'])"
      :time="localizeDate(lottery['created_at'])"
      @click="() => enterLottery(lottery.id)"
    />
    <empty-part
      desc-top="قرعه کشی"
      desc-bottom="قرعه کشی"
      v-if="pageStatus == 'resolved' && lotteries.length === 0"
    />
    <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
    <loader v-if="pageStatus == 'loading'" />
    <observer @intersect="intersected" />
  </section>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import { dateMixin } from '@mixin/dateMixin'
import EmptyPart from '@common/EmptyPart'
import LotterySingle from './subComponents/LotterySingle'

export default {
  components: {
    LotterySingle,
    EmptyPart
  },
  mixins: [dateMixin],
  setup() {
    // Fetch Bonuses
    const store = useStore()
    store.dispatch('lottery/fetchItems', { part: 'lottery' })
    const lotteries = computed(() => store.state.lottery.items)
    const pageStatus = computed(() => store.state.lottery.pageStatus)

    // Implementing intersection
    const intersected = () => {
      if (pageStatus.value == 'loading') return
      store.dispatch('lottery/fetchItems', { part: 'lottery' })
    }

    // Routing for entering offers
    const router = useRouter()
    const enterLottery = (id) => {
      router.push({ name: 'LotteryWinner', params: { id } })
    }

    // Clear offers
    onBeforeUnmount(() => store.dispatch('lottery/clearItems'))

    // ------- //
    return { lotteries, intersected, enterLottery, pageStatus }
  }
}
</script>

<style lang="scss" scoped></style>
