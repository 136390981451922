<template>
  <div :class="['lottery-wrapper', showType === 'simple' && 'lottery--simple']">
    <navbar msg="لیست برندگان قرعه‌کشی" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="winners-list" v-else-if="pageStatus == 'resolved'">
      <lottery-animation v-if="animate" class="animate-effect" />
      <svg
        class="lottery-logo"
        width="750px"
        height="512px"
        viewBox="0 0 750 512"
      >
        <path
          d="M679.26 322.18l0 -109.75c0,-52.39 -42.86,-95.25 -95.24,-95.25l-105.2 0 1.2 -0.74c3.78,-2.32 7.22,-4.99 10.36,-8.13 24.78,-24.77 24.77,-64.96 0,-89.73 -24.77,-24.77 -64.96,-24.77 -89.73,0 -10.16,10.16 -11.57,16.38 -17.21,28.84l-0.89 1.95 -7.55 27.39 -7.54 -27.36 -0.9 -1.98c-5.64,-12.45 -7.05,-18.68 -17.21,-28.84 -24.77,-24.77 -64.96,-24.77 -89.73,0 -24.77,24.77 -24.78,64.96 0,89.73 3.14,3.14 6.58,5.81 10.36,8.13l1.2 0.74 -105.2 0c-52.38,0 -95.24,42.86 -95.24,95.25l0 109.75 39.17 0 0 94.58c0,52.38 42.86,95.24 95.24,95.24l339.69 0c52.39,0 95.25,-42.86 95.25,-95.24l0 -94.58 39.17 0zm-39.17 -27.19l-27.19 0 0 0.06 -475.81 0 0 -0.06 -27.18 0 0 0.06 -11.99 0 0 -82.62c0,-37.44 30.63,-68.06 68.06,-68.06l418.03 0c37.44,0 68.06,30.62 68.06,68.06l0 82.62 -11.98 0 0 -0.06zm-27.19 27.19l0 94.58c0,37.43 -30.62,68.05 -68.06,68.05l-339.69 0c-37.43,0 -68.06,-30.62 -68.06,-68.05l0 -94.58 475.81 0zm-334.06 -284.38c14.16,-14.16 37.13,-14.16 51.29,0 7.22,7.22 7.71,12.1 11.69,20.9l16.02 58.11 -0.68 -0.19c-18.32,-5.04 -65.77,-14.98 -78.32,-27.53 -14.16,-14.16 -14.16,-37.13 0,-51.29zm192.32 0c14.16,14.16 14.16,37.13 0,51.29 -12.55,12.55 -60,22.49 -78.33,27.53l-0.67 0.19 16.04 -58.17c3.96,-8.74 4.45,-13.62 11.67,-20.84 14.16,-14.16 37.13,-14.16 51.29,0z"
        />
      </svg>
      <template v-if="showType == 'animate'">
        <transition-group name="slide-down" tag="div" class="profiles-wrapper">
          <offer-customer
            v-for="winner in winnersToShow"
            :key="winner['wallet']['id']"
            :name="winner['wallet']['name']"
            :image="winner['wallet']['image']"
            :id="winner['wallet']['id']"
            @click="enterCustomer(winner['wallet']['id'])"
            vertical
          />
        </transition-group>
        <template v-if="currentAddedWinner < winners.length">
          <button
            class="lottery-btn btn--start"
            v-if="winnersToShow.length === 0"
            @click="nextWinner"
          >
            <span>شروع کن</span>
          </button>
          <button class="lottery-btn btn--next" v-else @click="nextWinner">
            برنده
            <br />
            بعدی
          </button>
        </template>
        <div class="round-btns" v-if="currentAddedWinner === winners.length">
          <button class="round-btn" @click="resetWinners">
            <svg
              class="round-btn__icon"
              viewBox="0 0 512 512"
              style="wdith: 27px;height: 27px"
            >
              <path
                d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
			C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
			C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
			c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
			C512.919,81.362,504.815,71.632,493.815,70.629z"
              />
              <path
                d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
			c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
			c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
			c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
			c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
              />
            </svg>
            <span class="round-btn__text">دوباره</span>
          </button>
          <button
            class="round-btn"
            style="background-color: #c69d4e"
            v-if="currentAddedWinner === winners.length"
            @click="
              () => {
                showType = 'simple'
                resetWinners()
              }
            "
          >
            <svg viewBox="0 0 512 512" class="round-btn__icon">
              <path
                d="M394.58 154.91l0 -0.01c-5.24,-5.23 -13.8,-5.24 -19.04,0l-173.63 173.62 -65.45 -65.45c-5.24,-5.24 -13.8,-5.23 -19.04,0l0 0.01c-5.24,5.23 -5.24,13.8 0,19.04l74.97 74.97 0 0.01c5.24,5.23 13.81,5.24 19.05,0l183.14 -183.15c5.24,-5.24 5.24,-13.81 0,-19.04z"
              />
            </svg>
            <span class="round-btn__text">تأیید</span>
          </button>
        </div>
      </template>
      <template v-else>
        <section class="lottery-info">
          <h2 class="lottery-time">
            <span v-text="localizeDate(lottery['created_at'])" />
            <br />
            <span v-text="getHour(lottery['created_at'])" />
          </h2>
          <h1 class="lottery-name">
            {{ lottery?.name }}
          </h1>
          <div class="lottery-detail">
            <div class="lottery-detail__col">
              <p class="lottery-detail__text">تعداد شرکت کنندگان</p>
              <h4 class="lottery-detail__num">
                {{ lottery?.count?.toLocaleString() }}
              </h4>
            </div>
            <i class="lottery-detail__vr" />
            <div class="lottery-detail__col">
              <p class="lottery-detail__text">تعداد برندگان</p>
              <h4 class="lottery-detail__num">{{ winnersCount }}</h4>
            </div>
          </div>
        </section>
        <div class="profiles-wrapper">
          <offer-customer
            v-for="winner in winners"
            :key="winner['wallet']['id']"
            :name="winner['wallet']['name']"
            :image="winner['wallet']['image']"
            :id="winner['wallet']['id']"
            @click="enterCustomer(winner['wallet']['id'])"
            vertical
          />
          <button class="lottery-btn btn--again" @click="showType = 'animate'">
            <svg viewBox="0 0 512 512" style="wdith: 27px;height: 27px">
              <path
                d="M493.815,70.629c-11.001-1.003-20.73,7.102-21.733,18.102l-2.65,29.069C424.473,47.194,346.429,0,256,0
			C158.719,0,72.988,55.522,30.43,138.854c-5.024,9.837-1.122,21.884,8.715,26.908c9.839,5.024,21.884,1.123,26.908-8.715
			C102.07,86.523,174.397,40,256,40c74.377,0,141.499,38.731,179.953,99.408l-28.517-20.367c-8.989-6.419-21.48-4.337-27.899,4.651
			c-6.419,8.989-4.337,21.479,4.651,27.899l86.475,61.761c12.674,9.035,30.155,0.764,31.541-14.459l9.711-106.53
			C512.919,81.362,504.815,71.632,493.815,70.629z"
              />
              <path
                d="M472.855,346.238c-9.838-5.023-21.884-1.122-26.908,8.715C409.93,425.477,337.603,472,256,472
			c-74.377,0-141.499-38.731-179.953-99.408l28.517,20.367c8.989,6.419,21.479,4.337,27.899-4.651
			c6.419-8.989,4.337-21.479-4.651-27.899l-86.475-61.761c-12.519-8.944-30.141-0.921-31.541,14.459l-9.711,106.53
			c-1.003,11,7.102,20.73,18.101,21.733c11.014,1.001,20.731-7.112,21.733-18.102l2.65-29.069C87.527,464.806,165.571,512,256,512
			c97.281,0,183.012-55.522,225.57-138.854C486.594,363.309,482.692,351.262,472.855,346.238z"
              />
            </svg>
            انیمیشن
          </button>
        </div>
        <div class="filters-wrapper">
          <filters-table
            v-if="lotteryType === 'typical'"
            :filters="lottery?.filters"
            type="lottery"
          />
          <div class="single-item" v-else>
            این قرعه کشی بین مشتریانی انجام شد که در نظرسنجی
            <br />
            "{{ lottery['survey']['survey'] }}"
            <br />
            به گزینه "{{ lottery['survey']['answer'] }}" رأی داده‌اند.
          </div>
        </div>
      </template>
    </section>
    <failed-mode @refresh="fetchItem({ part: 'lottery', id })" v-else />
    <fixed-footer />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import dateConvertor from '@composable/dateConvertor'
import OfferCustomer from '@view/store/offer/subComponents/OfferCustomer'
import FiltersTable from '@common/FiltersTable'
import LotteryAnimation from './subComponents/LotteryAnimation'

export default {
  name: 'LotteryWinner',
  props: ['id'],
  components: {
    OfferCustomer,
    FiltersTable,
    LotteryAnimation
  },
  setup() {
    const { localizeDate, getHour } = dateConvertor()

    return { localizeDate, getHour }
  },
  data() {
    return {
      showType: 'simple',
      winnersToShow: [],
      currentAddedWinner: 0,
      animate: false,
      animateClock: null
    }
  },
  beforeRouteEnter(_, from, next) {
    const originPath = from.path
    next((thisComponent) => {
      if (originPath == '/store/lottery/new') thisComponent.showType = 'animate'
      else thisComponent.showType = 'simple'
    })
  },
  methods: {
    ...mapActions({
      fetchItem: 'lottery/fetchItem',
      clearItem: 'lottery/clearItem'
    }),
    nextWinner() {
      if (this.currentAddedWinner < this.winners.length) {
        this.winnersToShow.push(this.winners[this.currentAddedWinner])
        this.currentAddedWinner++
        if (this.animateClock) {
          this.animate = false
          clearInterval(this.animateClock)
          setTimeout(() => (this.animate = true), 100)
        } else {
          this.animate = true
        }
      }
    },
    resetWinners() {
      this.winnersToShow = []
      this.currentAddedWinner = 0
    },
    enterCustomer(id) {
      this.$router.push({ name: 'CustomerShow', params: { id } })
    }
  },
  computed: {
    ...mapState({
      lottery: (state) => state.lottery.item,
      pageStatus: (state) => state.lottery.pageStatus
    }),
    winners() {
      return this.lottery ? this.lottery.winners : []
    },
    winnersCount() {
      return this.winners?.length
    },
    lotteryType() {
      if (!this.lottery) return null
      return this.lottery['filters']?.['creation_type'] === '1'
        ? 'typical'
        : 'survey'
    }
  },
  created() {
    this.fetchItem({ part: 'lottery', id: this.id })
  },
  watch: {
    animate(curr) {
      if (curr) {
        this.animateClock = setTimeout(() => {
          this.animate = false
          this.animateClock = null
        }, 3000)
      }
    },
    showType() {
      window.scrollTo({ top: 0 })
    }
  },
  beforeUnmount() {
    this.clearItem()
  }
}
</script>

<style lang="scss" scoped>
.lottery-wrapper {
  overflow: hidden;
  width: 100%;
  padding: 60px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eee;
  min-height: 100vh;
}
.lottery--simple {
  background-color: #eee;
}
.lottery-logo {
  margin: 20px 0 15px;
  height: 50px;
  fill: #c69d4e;
}
.lottery-time {
  width: 100%;
  text-align: center;
  color: #c69d4e;
  margin: 0 0 15px;
  font-size: 14px;
}
.profiles-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.winners-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.animate-effect {
  z-index: 2;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: -800px;
}

.lottery-btns {
  z-index: 3;
  width: 100%;
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lottery-btn {
  z-index: 4;
  margin: 20px 0 30px;
  text-align: center;
  background-color: #c69d4e;
  color: #000;
  height: 40px;
  width: 120px;
  outline: none;
  border-radius: 15px;
}
.btn--start {
  margin-top: 0;
  animation: blinking 1.3s infinite;
}
.btn--next {
  background-color: #fff;
  animation: blinking 1.3s infinite;
  position: relative;
  color: #c69d4e;
  width: 85px;
  height: 85px;
  border-radius: 100%;
  border: 2px solid rgba(#c69d4e, 0.3);
}
.btn--again {
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: #000;
    margin-left: 10px;
    width: 20px;
    height: 20px;
  }
}
.lottery-btn-close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  color: #fff;
  background-color: #c69d4e;
  border-radius: 100%;
  margin: 0;
  position: relative;
  svg {
    fill: #000;
    width: 40px;
    height: 40px;
  }
}

.lottery-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lottery-name {
  width: 100%;
  text-align: center;
  font-size: 18px;
  // font-weight: bold;
  margin: 0 0 20px;
}
.lottery-detail {
  background-color: #ddd;
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0 20px;
  width: 96%;
  border-radius: 15px;
}
.lottery-detail__vr {
  width: 1px;
  height: 60%;
  background-color: rgba(#000, 0.3);
}
.lottery-detail__col {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lottery-detail__text {
  font-size: 14px;
  margin-bottom: 10px;
}
.lottery-detail__num {
  animation: blinking 1.3s infinite;
  font-size: 30px;
}
.load-pending--show {
  height: calc(100vh - 130px);
}
.single-item {
  background-color: #fff;
  text-align: center;
  line-height: 26px;
  padding: 0 10px;
}

// change in common css
.round-btns {
  z-index: 4;
}
</style>
