import http from './HttpService'
import qs from 'querystring'

export const addSurvey = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])

  return http.post('/store/survey/', formData)
}

export const searchSurvey = (vars) => {
  const query = qs.stringify({ ...vars })
  return http.get('/store/vote/?' + query)
}

export const disableSurvey = (id) => {
  return http.get('/store/survey/' + id + '/disable/')
}

export const doSurveyLottery = (data) => {
  const formData = new FormData()
  for (let key of Object.keys(data)) formData.append(key, data[key])
  return http.post('/store/survey/lottery/', formData)
}
