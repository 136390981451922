import moment from 'jalali-moment'

export const dateMixin = {
  data() {
    return {
      jalaliMonths: [
        { turn: '01', name: 'فروردین' },
        { turn: '02', name: 'اردیبهشت' },
        { turn: '03', name: 'خرداد' },
        { turn: '04', name: 'تیر' },
        { turn: '05', name: 'مرداد' },
        { turn: '06', name: 'شهریور' },
        { turn: '07', name: 'مهر' },
        { turn: '08', name: 'آبان' },
        { turn: '09', name: 'آذر' },
        { turn: '10', name: 'دی' },
        { turn: '11', name: 'بهمن' },
        { turn: '12', name: 'اسفند' }
      ]
    }
  },
  methods: {
    localizeDate(date, fourDigit = false) {
      const jsDate = new Date(date)
      const formattedDate = jsDate
        .toLocaleDateString('fa-IR')
        .replace(/([۰-۹])/g, (token) =>
          String.fromCharCode(token.charCodeAt(0) - 1728)
        )
      const [, year, month, day] = /(\d{4})\/(\d{1,2})\/(\d{1,2})/.exec(
        formattedDate
      )
      if (fourDigit) {
        return `${this.addZero(month)}${this.addZero(day)}`
      }

      return `${year}/${this.addZero(month)}/${this.addZero(day)}`
    },
    getDay(date) {
      const newDate = new Date(date)
      return this.localizeDate(
        `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDay()}`
      )
    },
    getHour(date) {
      const newDate = new Date(date)
      let hour = newDate.getHours()
      hour = hour >= 10 ? hour : '0' + hour
      let minute = newDate.getMinutes()
      minute = minute >= 10 ? minute : '0' + minute
      return `${hour}:${minute}`
    },
    standardDate(date) {
      const jsDate = new Date(date)
      const tehranOffset = jsDate.getTimezoneOffset()
      // Make it standard
      jsDate.setHours(jsDate.getHours() - Math.floor(-tehranOffset / 60))
      jsDate.setMinutes(jsDate.getMinutes() - (-tehranOffset % 60))

      return jsDate
    },
    formatDate(date) {
      const dateObj = new Date(date)
      const year = dateObj.getFullYear()
      const month =
        dateObj.getMonth() >= 9
          ? dateObj.getMonth() + 1
          : '0' + (dateObj.getMonth() + 1)
      const day =
        dateObj.getMonth() >= 10 ? dateObj.getDay() : '0' + dateObj.getDay()
      return `${year}/${month}/${day}`
    },
    addDays(days) {
      let result = new Date()
      result.setDate(result.getDate() + days)
      return `${result.getFullYear()}-${result.getMonth()}-${result.getDay()}`
    },
    generateExpireTime(day) {
      Date.prototype.addDays = function(days) {
        let date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
      }

      const newDate = new Date().addDays(day)

      return `${newDate.getFullYear()}-${newDate.getMonth() +
        1}-${newDate.getDate()}`
    },
    getRemainingDay(date1, date2) {
      const time1 = new Date(date1).getTime()
      const time2 = new Date(date2).getTime()

      return Math.floor((time2 - time1) / (1000 * 60 * 60 * 24))
    },
    addZero(n) {
      return +n >= 10 ? n : '0' + n
    },
    jalaliToMiladi({ day, month, year }) {
      const _day = this.addZero(+day)
      const _month = this.addZero(+month)
      const _year = year

      const m = moment(`${_year}/${_month}/${_day}`, 'jYYYY/jM/jD')
      return m.format('YYYY-MM-DD')
    }
  }
}
