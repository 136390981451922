<template>
  <div class="stat-wrapper">
    <section class="load-pending" v-if="pageStatus == 'loading'">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div class="chartTitle">
        <h3 class="chartTitle__text">تعداد برنده ‌ها</h3>
        <h4 class="chartTitle__number">
          {{ stat.count ? stat.count.toLocaleString() : 0 }}
        </h4>
      </div>
      <div class="stat-table">
        <div class="stat-table__row header--row">
          <h5 class="stat-table__cell">تاریخ</h5>
          <h5 class="stat-table__cell">زمان</h5>
          <h5 class="stat-table__cell">برنده</h5>
        </div>
        <div
          v-for="(lottery, idx) in lotteries"
          :class="['stat-table__row', idx % 2 === 0 && 'even--row']"
          :key="lottery.id"
        >
          <h5 class="stat-table__cell">
            {{ localizeDate(lottery['created_at']) }}
          </h5>
          <h5 class="stat-table__cell">
            {{ getHour(lottery['created_at']) }}
          </h5>
          <h5 class="stat-table__cell">
            {{ lottery['count']?.toLocaleString() }}
          </h5>
        </div>
        <div class="stat-table__row" v-if="lotteries.length < 1">
          <h5 class="stat-table__cell">
            ایجاد نشده
          </h5>
          <h5 class="stat-table__cell">
            0
          </h5>
          <h5 class="stat-table__cell">
            0
          </h5>
        </div>
      </div>
    </section>
    <failed-mode @refresh="getStats" v-else />
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { getItems } from '@service/ItemService'
import dateConvertor from '@composable/dateConvertor'

export default {
  name: 'LotteryStat',
  setup() {
    const store = useStore()
    const pageStatus = ref('loading')
    const lotteries = ref([])

    // Get all lotteries
    const getAllLotteries = async () => {
      pageStatus.value = 'loading'
      try {
        const { data } = await getItems('lottery', { page: 1, limit: 50 })
        lotteries.value = [...data.results]
      } catch (ex) {
        pageStatus.value = 'failed'
      }
    }

    const getStats = () => {
      getAllLotteries()
      // Get counts
      store.dispatch('fetchStat', { part: 'lottery' })
    }
    getStats()

    // use to resolved data fetching to show page
    const stat = computed(() => store.state.stat)
    watchEffect(() => {
      if (stat.value && stat.value.count >= 0) pageStatus.value = 'resolved'
    })

    const { localizeDate, getHour } = dateConvertor()

    onBeforeUnmount(() => {
      store.dispatch('clearStat')
    })
    return { lotteries, localizeDate, getHour, stat, getStats, pageStatus }
  }
}
</script>

<style scoped lang="scss"></style>
