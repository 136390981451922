<template>
  <div class="lottery">
    <img class="lottery__img" src="@img/lottery-back.jpg" />
    <h5 class="lottery__lottery">lottery</h5>
    <i class="lottery__gold-bg" />
    <svg
      class="lottery__logo"
      width="750px"
      height="512px"
      viewBox="0 0 750 512"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <path
          class="fil0"
          d="M679.26 322.18l0 -109.75c0,-52.39 -42.86,-95.25 -95.24,-95.25l-105.2 0 1.2 -0.74c3.78,-2.32 7.22,-4.99 10.36,-8.13 24.78,-24.77 24.77,-64.96 0,-89.73 -24.77,-24.77 -64.96,-24.77 -89.73,0 -10.16,10.16 -11.57,16.38 -17.21,28.84l-0.89 1.95 -7.55 27.39 -7.54 -27.36 -0.9 -1.98c-5.64,-12.45 -7.05,-18.68 -17.21,-28.84 -24.77,-24.77 -64.96,-24.77 -89.73,0 -24.77,24.77 -24.78,64.96 0,89.73 3.14,3.14 6.58,5.81 10.36,8.13l1.2 0.74 -105.2 0c-52.38,0 -95.24,42.86 -95.24,95.25l0 109.75 39.17 0 0 94.58c0,52.38 42.86,95.24 95.24,95.24l339.69 0c52.39,0 95.25,-42.86 95.25,-95.24l0 -94.58 39.17 0zm-39.17 -27.19l-27.19 0 0 0.06 -475.81 0 0 -0.06 -27.18 0 0 0.06 -11.99 0 0 -82.62c0,-37.44 30.63,-68.06 68.06,-68.06l418.03 0c37.44,0 68.06,30.62 68.06,68.06l0 82.62 -11.98 0 0 -0.06zm-27.19 27.19l0 94.58c0,37.43 -30.62,68.05 -68.06,68.05l-339.69 0c-37.43,0 -68.06,-30.62 -68.06,-68.05l0 -94.58 475.81 0zm-334.06 -284.38c14.16,-14.16 37.13,-14.16 51.29,0 7.22,7.22 7.71,12.1 11.69,20.9l16.02 58.11 -0.68 -0.19c-18.32,-5.04 -65.77,-14.98 -78.32,-27.53 -14.16,-14.16 -14.16,-37.13 0,-51.29zm192.32 0c14.16,14.16 14.16,37.13 0,51.29 -12.55,12.55 -60,22.49 -78.33,27.53l-0.67 0.19 16.04 -58.17c3.96,-8.74 4.45,-13.62 11.67,-20.84 14.16,-14.16 37.13,-14.16 51.29,0z"
        />
      </g>
    </svg>
    <div class="lottery__left">
      <h3 class="lottery__title">تاریخ</h3>
      <h4 class="lottery__time">{{ time }}</h4>
      <h4 class="lottery__time time--hour">{{ hour }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LotterySingle',
  props: {
    hour: {
      type: String
    },
    time: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery {
  position: relative;
  width: 96%;
  border-radius: 20px;
  background-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0;
  height: 160px;
  justify-content: flex-end;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
}

// .lottery__right {
//   width: 60%;
//   height: 160px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   padding: 0;
// }
.lottery__img {
  right: 0;
  top: 0;
  position: absolute;
  height: 100%;
  // max-width: ;
}

.lottery__left {
  // width: 40%;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 80px;
}

.lottery__title {
  font-size: 14.2px;
  margin: 0 0 6px;
  color: #bbb;
  font-weight: bold;
}
.lottery__time {
  margin: 0 0 0;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  color: #333;
}
.time--hour {
  font-weight: bold;
  color: #aaa;
  font-size: 16px;
  margin-top: 2px;
}
.lottery__lottery {
  z-index: 4;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 20px;
  margin: auto 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  transform: rotate(-90deg) translateY(-18px);
}

.lottery__logo {
  position: absolute;
  z-index: 2;
  right: 40px;
  width: 60px;
  height: 60px;
  fill: #c69d4e;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.lottery__gold-bg {
  position: absolute;
  z-index: 3;
  width: 40px;
  height: 100%;
  background-color: #c69d4e;
  top: 0;
  bottom: 0;
  left: 0;
}
</style>
