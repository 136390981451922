<template>
  <form novalidate class="form-wrapper" @submit.prevent="submitForm">
    <div class="filtered-customers">
      <span
        class="count"
        v-text="
          lotteryOptions ? lotteryOptions['customersCount'].toLocaleString() : 0
        "
      />
      <h3 class="title">مشتری آماده شرکت در قرعه کشی</h3>
    </div>
    <div class="form-part">
      <base-input
        width="100%"
        class="info__input"
        v-model="formItems['lotteryName']"
        animate-type="decrease"
        label="نام قرعه ‌کشی"
        star-label
      />
    </div>
    <div class="form-part" v-if="lotteryOptions['lotteryType'] != 'survey'">
      <base-input-number
        style="width:100%"
        v-model="formItems['lotteryUnit']"
        label="مبلغ هر شانس"
      />
    </div>
    <div class="credit-buttons">
      <a class="button button--form" @click="modals['winner'] = true">
        <span v-if="!formItems['lotteryWinnersCount']" class="button__text">
          تعداد برندگان
        </span>
        <span v-else class="button__text text--gold">
          {{ formItems['lotteryWinnersCount'] }}
          نفر
        </span>
      </a>
    </div>
    <!-- <div class="table table--form">
      <div class="table__row">
        <h6 class="table__cell cell--title">
          تعداد برنده
        </h6>
        <h6 class="table__cell cell--amount">
          {{
            formItems['lotteryWinnersCount']
              ? formItems['lotteryWinnersCount']
              : 0
          }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row">
        <h6 class="table__cell cell--title">
          هر قرعه
        </h6>
        <h6 class="table__cell cell--price ">
          {{ lotteryTablePrice.toLocaleString() }}
        </h6>
      </div>
      <i class="table__hr" />
      <div class="table__row">
        <h6 class="table__cell cell--title">
          هزینه کل
        </h6>
        <h6 class="table__cell cell--price ">
          {{
            formItems['lotteryWinnersCount']
              ? (
                  formItems['lotteryWinnersCount'] * lotteryTablePrice
                ).toLocaleString()
              : 0
          }}
        </h6>
      </div>
    </div> -->
    <div class="credit-buttons" style="margin: 0 0 30px">
      <button type="submit" class="button button--black">
        <span class="button__text">قرعه کشی</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
  </form>
  <modal
    :is-open="modals['winner']"
    @modalClosed="modals['winner'] = false"
    height="60%"
    title="تعداد برندگان"
  >
    <ul class="items-wrapper">
      <li v-for="num in winners" :key="num">
        <a
          :class="num === formItems['lotteryWinnersCount'] && 'active'"
          @click="() => handleSelectWinnersCount(num)"
        >
          {{ num }} <span>نفر</span>
        </a>
      </li>
    </ul>
  </modal>
</template>

<script>
// #TODO winners don't exceed over total counts
import { mapState, mapActions } from 'vuex'
import { addLottery } from '@service/LotteryService'
import { doSurveyLottery } from '@service/SurveyService'
import {
  schemaMaker,
  reqString,
  validateForms,
  modalNumber
} from '@valid/JoiValidation'
import BaseInputNumber from '@common/BaseInputNumber'

const isFormValid = schemaMaker({
  name: reqString('نام قرعه‌کشی'),
  winner: modalNumber('تعداد برندگان', 1)
})

export default {
  name: 'LotteryShow',
  components: {
    BaseInputNumber
  },
  data() {
    return {
      modals: {
        winner: false
      },
      formItems: {
        lotteryWinnersCount: '',
        lotteryName: '',
        lotteryUnit: ''
      },
      // Array of numbers for winners
      winners: [...Array.from({ length: 30 }, (_, i) => i + 1)],
      btnLoading: false,
      isDone: false,
      lotteryTablePrice: 500
    }
  },
  methods: {
    ...mapActions(['addToast']),
    handleSelectWinnersCount(n) {
      this.formItems['lotteryWinnersCount'] = n
      this.modals['winner'] = false
    },
    async submitForm() {
      if (this.btnLoading || this.isDone) return

      const {
        lotteryName: name,
        lotteryWinnersCount: winner,
        lotteryUnit: unit
      } = this.formItems

      const errors = validateForms({ name, winner }, isFormValid)

      if (errors && errors.length > 0) {
        for (let key of errors) {
          const message = { type: 'error', message: key }
          this.addToast(message)
        }
        return
      }

      const finalForm = { ...this.filters }
      finalForm['name'] = name
      finalForm['winner'] = winner

      this.btnLoading = true

      const { lotteryType, type, id } = this.lotteryOptions

      if (lotteryType === 'common') {
        try {
          if (unit) finalForm['chance'] = unit
          const { data } = await addLottery(finalForm)
          this.isDone = true
          this.btnLoading = false
          this.addToast({
            type: 'success',
            message: 'قرعه‌ کشی با موفقیت انجام شد.'
          })

          this.$store.dispatch('fetchHomeData')
          setTimeout(
            () =>
              this.$router.push({
                name: 'LotteryWinner',
                params: { id: data.id }
              }),
            2000
          )
        } catch (ex) {
          if (ex.response) {
            switch (ex.response.status) {
              case 400:
                this.addToast({
                  type: 'error',
                  message: 'تعداد برندگان نباید بیشتر از تعداد شرکت کننده باشد.'
                })
                break
              case 402:
                this.$store.dispatch('profile/handleCreditModal', {
                  status: 'open',
                  type: 'credit'
                })
                break
              default:
                this.addToast({
                  type: 'error',
                  status: 'درخواست شما موفقیت‌آمیز نبود.'
                })
                break
            }
          }
        } finally {
          this.btnLoading = false
        }
      } else if (lotteryType === 'survey') {
        try {
          const { data } = await doSurveyLottery({
            type,
            count: winner,
            id,
            name
          })
          this.isDone = true
          this.btnLoading = false
          this.addToast({
            type: 'success',
            message: 'قرعه‌کشی با موفقیت انجام شد.'
          })

          this.$store.dispatch('fetchHomeData')
          setTimeout(
            () =>
              this.$router.push({
                name: 'LotteryWinner',
                params: { id: data.lottery }
              }),
            2000
          )
        } catch (ex) {
          if (ex.response) {
            switch (ex.response.status) {
              case 400:
                this.addToast({
                  type: 'error',
                  message: 'تعداد برندگان نباید بیشتر از تعداد شرکت کننده باشد.'
                })
                break
              case 402:
                this.$store.dispatch('profile/handleCreditModal', {
                  status: 'open',
                  type: 'credit'
                })
                break
              default:
                this.addToast({
                  type: 'error',
                  status: 'درخواست شما موفقیت‌آمیز نبود.'
                })
                break
            }
          }
        } finally {
          this.btnLoading = false
        }
      }
    }
  },
  computed: {
    ...mapState({
      lotteryOptions: (state) => state.lottery.lotteryOptions,
      filters: (state) => state.customer.filterData
    })
  },
  created() {
    if (!this.lotteryOptions)
      this.$router.push({
        name: 'LotteryHome'
      })
  }
}
</script>

<style scoped lang="scss">
.form-wrapper {
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
  min-height: calc(100vh - 350px);
}

.form-part {
  width: 90%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}

.form-part__row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.row--checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row--checkbox input[type='checkbox'] {
  margin: 0 10px 2px 0;
}

.row--checkbox label {
  margin: 0;
}

.form-part__title {
  width: 100%;
  font-size: 16px;
  color: #111;
  margin: 25px 0 0;
  text-align: center;
}

.form-part__btn {
  cursor: pointer;
  display: inline-block;
  background-color: #111;
  color: #fff;
  padding: 2px 3px 0;
  min-width: 25px;
  border-radius: 5px;
  text-align: center;
  margin: 0 3px;
  transition: all 0.5s ease;
  outline: none;
}
.form-part__price {
  font-size: 18px;
  margin-right: 10px;
  &::after {
    content: 'ريال';
    font-size: 12px;
  }
}

// change in common css
.credit-buttons {
  margin: 25px 0 30px;
  flex-direction: column;
}
.button--form {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  margin: 0;
}
.button--modal .button__text {
  font-size: 16px;
}
.button__text.text--gold {
  font-size: 20px;
  color: #c69d4e;
  margin: 0;
}
</style>
