// import { ref } from 'vue'
import moment from 'jalali-moment'

const addZero = (n) => {
  return +n >= 10 ? n : '0' + n
}

// formatTime takes a time length in seconds and returns the time in
// minutes and seconds
const formatTime = (timeInSeconds) => {
  const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8)

  return `${result.substr(3, 2)}:${result.substr(6, 2)}`
}

const getHour = (date) => {
  const newDate = new Date(date)
  let hour = addZero(newDate.getHours())
  let minute = addZero(newDate.getMinutes())
  return `${hour}:${minute}`
}

const localizeDate = (date, fourDigit = false) => {
  const jsDate = new Date(date)
  const formattedDate = jsDate
    .toLocaleDateString('fa-IR')
    .replace(/([۰-۹])/g, (token) =>
      String.fromCharCode(token.charCodeAt(0) - 1728)
    )
  const [, year, month, day] = /(\d{4})\/(\d{1,2})\/(\d{1,2})/.exec(
    formattedDate
  )
  if (fourDigit) {
    return `${addZero(month)}${addZero(day)}`
  }

  return `${year}/${addZero(month)}/${addZero(day)}`
}

const generateExpireTime = (day) => {
  Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  const newDate = new Date().addDays(day)

  return `${newDate.getFullYear()}-${newDate.getMonth() +
    1}-${newDate.getDate()}`
}

const getRemainingDay = (date1, date2) => {
  const time1 = new Date(date1).getTime()
  const time2 = new Date(date2).getTime()

  return Math.floor((time2 - time1) / (1000 * 60 * 60 * 24))
}

const applyTimeZone = (date) => {
  const jsDate = new Date(date)
  const tehranOffset = jsDate.getTimezoneOffset()
  // Make it standard
  jsDate.setHours(jsDate.getHours() - Math.floor(-tehranOffset / 60))
  jsDate.setMinutes(jsDate.getMinutes() - (-tehranOffset % 60))

  return jsDate
}

const jalaliMonths = [
  { turn: '01', name: 'فروردین' },
  { turn: '02', name: 'اردیبهشت' },
  { turn: '03', name: 'خرداد' },
  { turn: '04', name: 'تیر' },
  { turn: '05', name: 'مرداد' },
  { turn: '06', name: 'شهریور' },
  { turn: '07', name: 'مهر' },
  { turn: '08', name: 'آبان' },
  { turn: '09', name: 'آذر' },
  { turn: '10', name: 'دی' },
  { turn: '11', name: 'بهمن' },
  { turn: '12', name: 'اسفند' }
]

const jalaliToMiladi = ({ day, month, year }) => {
  const _day = addZero(day)
  const _month = jalaliMonths.find((item) => item.name === month)['turn']
  const _year = year

  const m = moment(`${_year}/${_month}/${_day}`, 'jYYYY/jM/jD')
  return m.format('YYYY-MM-DD')
}

const dateConvertor = () => {
  return {
    localizeDate,
    getHour,
    generateExpireTime,
    getRemainingDay,
    applyTimeZone,
    jalaliToMiladi,
    formatTime
  }
}

export default dateConvertor
